<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">试卷管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6em;">试卷名称:</span>
              <el-input v-model="retrievalData.paperName" type="text" size="small" placeholder="请输入试卷名称" clearable />
            </div>
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6em;">题库名称:</span>
              <el-input v-model="retrievalData.bankName" type="text" size="small" placeholder="请输入题库名称" clearable />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round icon="el-icon-plus"
              @click="addExaminationPaper">新建</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="试卷名称" align="left" show-overflow-tooltip fixed prop="paperName" minWidth="300px">
              <template slot-scope="scope">
                  <div v-if="!scope.row.nameShow">
                    <span> {{ scope.row.paperName }}</span>
                    <i class="el-icon-edit" style="margin-left: 10px;"
                      @click="$set(tableData[scope.$index], 'nameShow', true)"></i>
                  </div>
                  <el-input v-else v-model="scope.row.paperName" :key="scope.row.ruleId" maxlength="30" size="mini"
                    show-word-limit autofocus @blur="() => {
                        editRowName(scope.row);
                      }
                      "></el-input>
                </template>
              </el-table-column>
              <el-table-column label="题库名称" align="left" show-overflow-tooltip prop="bankName" minWidth="300px" />
              <el-table-column label="试卷数量" align="left" show-overflow-tooltip prop="paperNum" minWidth="150px" />
              <el-table-column label="试卷类型" align="left" show-overflow-tooltip prop="examType" minWidth="120px">
                <template slot-scope="scope">
                  {{ $setDictionary("TOOL_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column label="组卷形式" align="left" show-overflow-tooltip prop="compositionType" minWidth="120px">
                <template slot-scope="scope">
                  {{ $setDictionary("TOOL_COMPOSITION_TYPE", scope.row.compositionType) }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" align="left" show-overflow-tooltip prop="createTime" minWidth="150">
                <template slot-scope="scope">
                  {{
                    scope.row.createTime | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="是否组卷完成" align="left" show-overflow-tooltip prop="complete" minWidth="150">
                <template slot-scope="scope">
                  {{
                    scope.row.complete ? "是" : "否"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="组卷消息" align="left" show-overflow-tooltip prop="message" minWidth="150px" />
              <el-table-column label="操作" align="center" width="260px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="seeView(scope.row)">查看</el-button>
                  <el-button :disabled="scope.row.compositionType === '1'" type="text" style="padding:0px 5px" size="mini"
                    @click="copyExaminationPaper(scope.row)">复制</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="goExaminationPaperList(scope.row)">试卷列表</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="bulkDownloadExaminationPaperList(scope.row.ruleId)">打包下载</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="deleteExaminationPaperList(scope.row.ruleId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "inkQuestionBank_examinationPaperManage_tactics",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 检索数据
      retrievalData: {
        bankName: "", // 题库名称
        paperName: "", // 试卷名称
      },
    };
  },
  mixins: [List],
  created() {

  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.bankName) {
        params.bankName = this.retrievalData.bankName;
      }
      if (this.retrievalData.paperName) {
        params.paperName = this.retrievalData.paperName;
      }
      this.doFetch({
        url: "/biz/exam/bank/extract/rule/ruleList",
        params,
        pageNum
      }, true, 6);
    },
    // 修改试卷名称
    editRowName(row) {
      const params = {
        paperName: row.paperName,
        ruleId: row.ruleId
      };
      this.$post("/biz/exam/bank/extract/rule/updName", params, 3000, true, 6).then(res => {
        this.$message({
          type: "success",
          message: "修改成功"
        });
        this.getData(-1);
      });
    },
    // 新增 - 试卷
    addExaminationPaper() {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsRule",
      });
    },
    // 查看
    seeView(row) {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsRule",
        query: {
          ruleId: row.ruleId || "",
          ruleIdType: 'seeView'
        }
      });
    },
    // 复制
    copyExaminationPaper(row) {
      if (row.compositionType == '1') {
        // 自行组卷
        this.$router.push({
          path: "/inkQuestionBank/examinationPaperManage/tacticsRule",
          query: {
            ruleId: row.ruleId || "",
            ruleIdType: 'copyUpdate'
          }
        });
      } else {
        // 智能组卷
        this.$router.push({
          path: "/inkQuestionBank/examinationPaperManage/tacticsRule",
          query: {
            ruleId: row.ruleId || "",
            ruleIdType: 'copyView'
          }
        });
      }

    },
    // 试卷列表 
    goExaminationPaperList(info) {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsList",
        query: {
          ruleId: info.ruleId,
          ruleName: info.paperName
        }
      });
    },
    // 打包下载
    bulkDownloadExaminationPaperList(ruleId) {
      this.$post("/biz/exam/bank/extract/rule/asyncDownloadPaperBatch", { ruleId }, 3000, true, 6)
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 删除
    deleteExaminationPaperList(ruleId) {
      this.$confirm('试卷删除后不可恢复，确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post("/biz/exam/bank/extract/rule/delete", { ruleId }, 3000, true, 6)
          .then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData(this.pageNum);
          })
          .catch(() => {
            return;
          });
      }).catch(() => {

      });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  // beforeRouteLeave: resetKeepAlive
};
</script>

<style lang="less" scoped></style>
